import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import CrederePropostaModel from 'src/model/usuario/CrederePropostaModel'
import SituacaoCrederePropostaModel from 'src/model/usuario/SituacaoCrederePropostaModel'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import CredereSimulacaoModel from 'src/model/usuario/CredereSimulacaoModel'
import QRadioCustom from 'src/components/RadioCustom/QRadioCustom.vue'
import BiitrixModel from 'src/model/usuario/BiitrixModel'
import Upload from 'src/components/Upload.vue'
import Bancos from 'src/core/mixin/Bancos'
import { LocalStorage, clone } from 'quasar'

export default {
  name: 'PropostaForm',
  components: { DpForm, Upload, QRadioCustom },
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters, Bancos],
  data () {
    return {
      modelPadrao: new CrederePropostaModel(),
      modelSituacaoCredereProposta: new SituacaoCrederePropostaModel(),

      logado: LocalStorage.getItem('logado'),
      situacoes: [],

      dados: null,
      anuncio: null,
      parcela: 0,

      modelCredereSimulacao: new CredereSimulacaoModel(),
      resultSimulacao: null,
      resultSimulacaoFull: [],

      qtdParcelas: [12, 24, 36, 48, 60],
      qtdParcelasFull: [12, 24, 36, 48, 60],
      formProposta: null,
      salvando: false,
      buscando: true,
      banco_inicio: null,
      parcela_inicio: null,
      condition_id_inicio: null,
      diretivaSemMascara: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      modelBiitrix: new BiitrixModel()
    }
  },
  watch: {
    'formProposta.valor_parcela_resposta' () {
      this.calcularValorTotalFinanciamento()
    },
    'formProposta.parcela_resposta' () {
      this.calcularValorTotalFinanciamento()
    },
    'formProposta.valor_financiado_resposta' (value) {
      if (this.dados.valor && (this.dados.valor - value) > 0) {
        this.formProposta.valor_extras_resposta = (this.dados.valor - value)
      } else {
        this.formProposta.valor_extras_resposta = 0
      }
    },
    'modelPadrao.form.situacao_credere_proposta_id' (value) {
      // let data = this.resultSimulacaoFull.findIndex((v) => {
      //   return v.condition_id === this.formProposta.condition_id
      // })
      // if (data) {
      //   data = this.resultSimulacaoFull[0]
      //   this.formProposta.valor_financiado_resposta = data
      //   this.formProposta.valor_total_resposta = data
      //   this.formProposta.valor_extras_resposta = data
      //   this.formProposta.valor_parcela_resposta = data
      //   this.formProposta.parcela_resposta = data
      // }
      this.resultSimulacao = []
      if (value === 4) {
        this.qtdParcelas = [this.parcela_inicio]
        this.resultSimulacao[`parce${this.parcela_inicio}`] = this.resultSimulacaoFull
          .filter(e => e.parcela === this.parcela_inicio && e.condition_id === parseInt(this.condition_id_inicio)).sort(this.sortValorPrimeiraParcela)
        this.formProposta.condition_id = this.condition_id_inicio
        this.parcela = this.parcela_inicio
      } else {
        this.qtdParcelas = this.qtdParcelasFull
        if (value === 5) {
          this.qtdParcelasFull.map((v) => {
            this.resultSimulacao[`parce${v}`] = this.resultSimulacaoFull
              .filter(e => e.parcela === v && e.condition_id !== parseInt(this.condition_id_inicio)).sort(this.sortValorPrimeiraParcela)
          })
          if (this.formProposta && this.formProposta.condition_id !== null) {
            if (this.formProposta.condition_id === this.condition_id_inicio) {
              this.formProposta.condition_id = 61
            }
          }
        } else {
          this.qtdParcelasFull.map((v) => {
            this.resultSimulacao[`parce${v}`] = this.resultSimulacaoFull
              .filter(e => e.parcela === v).sort(this.sortValorPrimeiraParcela)
          })
        }
      }
    }
  },
  created () {
  },
  mounted () {
    this.getSituacoes(0)
  },
  methods: {
    getSituacoes (exceto = 0) {
      this.modelSituacaoCredereProposta.getListagem().then((res) => {
        if (this.condition_id_inicio <= 60) {
          this.situacoes = res && res.dados ? res.dados.filter((v) => {
            return (this.condition_id_inicio <= 60 && v.id !== 4)
          }) : []
        } else {
          this.situacoes = res && res.dados ? res.dados.filter((v) => {
            return v.id !== exceto
          }) : []
        }
      }).catch(erro => {
        this.notificacao(erro.msg)
        console.error(erro)
      })
    },
    async trataForm () {
      this.buscando = false
      const form = this.modelPadrao.form

      let dadosSimulacao = null
      let dados = {}
      let anuncio = null

      // Dados Simulação credere
      dadosSimulacao = JSON.parse(form.request_log ? form.request_log.response : null)
      let simulacao = null
      if (dadosSimulacao) simulacao = dadosSimulacao.complete_proposal ?? dadosSimulacao.proposal

      if (simulacao) {
        const { proposal_attempt: tentativa } = simulacao
        if (tentativa) {
          let { bank: banco, financed_amount_in_cents: valor, term_financing: parcelas, quota_in_cents: parcela, created_at: data } = tentativa
          valor = (valor / 100)
          dados = { valor, banco: banco.name, parcelas, valorParcela: (parcela.toFixed(2) / 100), data }
        }
      }
      /* Fim - Dados Simulação credere */

      // Dados do Anuncio
      if (form.anuncio_id && form.anuncio && form.anuncio.bem && form.banco_id) {
        this.anuncio = form.anuncio
      } else if (form.simulacao && form.simulacao.anuncio && form.simulacao.anuncio.bem) {
        this.anuncio = form.simulacao.anuncio
      }

      if (this.anuncio && this.anuncio.bem) {
        const marca = this.anuncio.bem.versao && this.anuncio.bem.versao.modelo && this.anuncio.bem.versao.modelo.marca ? this.anuncio.bem.versao.modelo.marca.nome : null
        const modelo = this.anuncio.bem.versao && this.anuncio.bem.versao.modelo ? this.anuncio.bem.versao.modelo.nome : null
        const versao = this.anuncio.bem.versao ? this.anuncio.bem.versao.nome : null

        anuncio = {
          nome: `${marca} ${modelo} ${versao}`,
          anuncio_id: this.anuncio.id,
          bem_id: this.anuncio.bem.id
        }
      }
      /* Dados do Anuncio */

      // Preenchendo os dados
      dados.situacao = form.credere_proposta_tentativas && form.credere_proposta_tentativas.length > 0 ? form.credere_proposta_tentativas[0].credere_attempt_status : null
      dados.cliente = form.usuario && form.banco_id ? form.usuario : form.simulacao && form.simulacao.usuario ? form.simulacao.usuario : null
      dados.anuncio = anuncio

      if (!dados.data) dados.data = form.created_at
      if (!dados.banco && form.banco_id) dados.banco = this.bancoOptions.find(e => parseInt(e.id) === parseInt(form.banco_id)) ? this.bancoOptions.find(e => parseInt(e.id) === parseInt(form.banco_id)).name : null
      if (!dados.parcelas && form.banco_id) dados.parcelas = form.parcela
      if (!dados.valorParcela && form.banco_id) dados.valorParcela = form.valor_parcela
      if (!dados.valor && form.banco_id) dados.valor = form.valor_financiado_credere
      if (form.credere_proposta_id) dados.credere_proposta_id = form.credere_proposta_id

      this.dados = dados
      this.parcela = dados && dados.parcelas ? dados.parcelas : null

      this.formProposta = {
        valor_financiado_resposta: form.valor_financiado_resposta,
        valor_total_resposta: form.valor_total_resposta,
        valor_extras_resposta: form.valor_extras_resposta,
        valor_parcela_resposta: form.valor_parcela_resposta,
        parcela_resposta: form.parcela_resposta,
        banco_id: form.banco_id ? form.banco_id.toString() : null,
        parcela: form.parcela,
        valorParcela: form.valor_parcela ?? 0,
        valorTotal: form.valor_total ?? 0,
        valor_financiado_credere: form.valor_financiado_credere ?? 0,
        valor_extra_credere: form.valor_extra_credere ?? 0,
        simulacao_id: parseInt(form.simulacao_id),
        condition_id: form.condition_id ? parseInt(form.condition_id) : null
      }
      /* */
      this.condition_id_inicio = form.condition_id ? parseInt(form.condition_id) : null
      this.parcela_inicio = form.parcela
      this.banco_id_inicio = form.banco_id ? form.banco_id.toString() : null
      if (form.condition_id <= 60) {
        this.situacoes = this.situacoes.filter((v) => {
          return v.id !== 4
        })
      }

      this.simulacaoParcelas(form.simulacao)
    },

    irRota (rota) {
      this.$router.push(rota)
    },

    irRotaBlank (rota) {
      if (typeof rota === 'string') {
        window.open(rota)
      } else {
        let routeData = this.$router.resolve(rota)
        window.open(routeData.href, '_black')
      }
    },
    simulacaoParcelas (simulacao) {
      const resquet = simulacao && simulacao.request_log && simulacao.request_log.response ? JSON.parse(simulacao.request_log.response) : null
      let resultSimulacao = []

      if (resquet && resquet.data && resquet.data.conditions) {
        resquet.data.conditions.map(ele => {
          if (ele.process_task && ele.process_task.result && ele.process_task.result.success === true && (ele.pre_approval_status === 2 || ele.pre_approval_status === 3)) {
            const add = {
              simulacao_id: simulacao.id,
              condition_id: ele.id ? ele.id : null,
              parcela: parseInt(ele.installments),
              status_aprovacao: ele.pre_approval_status,
              banco: ele.bank && ele.bank.name ? ele.bank.name : null,
              banco_code: ele.bank && ele.bank.febraban_code ? ele.bank.febraban_code : null,
              banco_full: ele.bank,
              valorPrimeiraParcela: parseFloat(ele.first_installment_value / 100),
              valorLiberado: parseFloat(ele.financed_amount / 100)
            }

            resultSimulacao.push(add)
          }
        })
      }
      this.resultSimulacaoFull = resultSimulacao
      this.resultSimulacao = []

      this.resultSimulacao.parce12 = resultSimulacao.filter(e => e.parcela === 12).sort(this.sortValorPrimeiraParcela)
      this.resultSimulacao.parce24 = resultSimulacao.filter(e => e.parcela === 24).sort(this.sortValorPrimeiraParcela)
      this.resultSimulacao.parce36 = resultSimulacao.filter(e => e.parcela === 36).sort(this.sortValorPrimeiraParcela)
      this.resultSimulacao.parce48 = resultSimulacao.filter(e => e.parcela === 48).sort(this.sortValorPrimeiraParcela)
      this.resultSimulacao.parce60 = resultSimulacao.filter(e => e.parcela === 60).sort(this.sortValorPrimeiraParcela)
    },

    sortValorPrimeiraParcela (a, b) {
      return a.valorPrimeiraParcela < b.valorPrimeiraParcela ? -1 : a.valorPrimeiraParcela > b.valorPrimeiraParcela ? 1 : 0
    },

    parcelaSelecionada (item) {
      // if (item.condition_id && item.simulacao_id) {
      if (item.simulacao_id) {
        if (this.formProposta.condition_id === item.condition_id) {
          this.formProposta.condition_id = 61
        } else {
          this.formProposta = clone(item)
        }
        if (this.formProposta.condition_id !== this.condition_id_inicio) {
          this.modelPadrao.form.situacao_credere_proposta_id = 5
        }

        this.formProposta.banco_id = this.bancoOptions.find(e => parseInt(e.id) === parseInt(item.banco_code)).id
      } else {
        this.notificacao('aviso', 'Esta parcela não foi Pré Aprovado!')
      }
    },

    calcularValorTotalFinanciamento () {
      this.formProposta.valor_total_resposta = this.formProposta.valor_parcela_resposta * this.formProposta.parcela_resposta
    },

    submitProposta () {
      this.salvando = true
      const aviso = this.notificacao('enviando')
      const send = {
        id: this.$route.params.id,
        situacao_credere_proposta_id: this.modelPadrao.form.situacao_credere_proposta_id,
        banco_id: this.formProposta.banco_id,
        banco: this.bancoOptions.find(e => parseInt(e.id) === parseInt(this.formProposta.banco_id)) ? this.bancoOptions.find(e => parseInt(e.id) === parseInt(this.formProposta.banco_id)).name : null
      }

      if (this.formProposta.parcela) {
        send.parcela = this.formProposta.parcela
        send.valor_parcela = this.formProposta.valorParcela
        send.valor_total = this.formProposta.valorTotal
        send.valor_financiado_credere = this.formProposta.valor_financiado_credere
        send.valor_extra_credere = this.formProposta.valor_extra_credere
      }

      console.log('this.formProposta.condition_id', this.formProposta.condition_id)
      console.log('this.formProposta', this.formProposta)

      if (send.situacao_credere_proposta_id === 5) {
        if (this.formProposta.condition_id > 61 && this.formProposta.condition_id !== '') {
          send.valor_financiado_resposta = this.formProposta.valorLiberado
          send.valor_total_resposta = this.formProposta.valor_total_resposta
          send.valor_extras_resposta = this.formProposta.valor_extras_resposta
          send.valor_parcela_resposta = this.formProposta.valorPrimeiraParcela
          send.parcela_resposta = this.formProposta.parcela
        } else {
          send.valor_financiado_resposta = this.formProposta.valor_financiado_resposta
          send.valor_total_resposta = this.formProposta.valor_total_resposta
          send.valor_extras_resposta = this.formProposta.valor_extras_resposta
          send.valor_parcela_resposta = this.formProposta.valor_parcela_resposta
          send.parcela_resposta = this.formProposta.parcela_resposta
        }
      } else {
        send.valor_financiado_resposta = 0
        send.valor_total_resposta = 0
        send.valor_extras_resposta = 0
        send.valor_parcela_resposta = 0
        send.parcela_resposta = 0
      }

      if (!send.banco_id || send.banco_id === '') {
        if (this.formProposta.simulacao_id) send.simulacao_id = this.formProposta.simulacao_id
        if (this.formProposta.condition_id !== null) {
          send.condition_id = this.formProposta.condition_id
          let dados = this.resultSimulacaoFull.find(e => parseInt(e.condition_id) === parseInt(send.condition_id))
          if (dados) {
            send.banco = dados.banco
            send.banco_id = dados.banco_code
          }
        }
      }

      // if (!this.formProposta.banco_id) {
      //   this.notificacao('aviso', 'Selecione uma parcela diferente da anterior!')
      //   return false
      // }
      delete send.parcela
      const model = new CrederePropostaModel()
      model.recurso = 'credere/propostas-update'
      model.salvar(send).then(() => {
        aviso()
        this.salvando = false
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
        this.$router.push({ name: 'credere/propostas.grid' })
      }).catch(error => {
        aviso()
        this.salvando = false
        this.notificacao('erro', error.msg)
      })
    }
  }
}

import PadraoModel from 'src/core/model/PadraoModel.js'

export default class SituacaoCrederePropostaModel extends PadraoModel {
  recurso = 'situacoes-credere-propostas'
  microServico = 'usuario'
  include = null
  searchFixo = null
  nome = 'Situações Credere Proposta'

  colunasGrid = []

  form = {}

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
